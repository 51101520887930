<template>
    <div class="index">
        <!-- 头部导航组件 -->
        <navbar-header></navbar-header>

        <div class="box_title_right">
            <span class="el-icon-location-outline"></span><router-link to="/"> 首页 </router-link> / <span class="name">货源大厅</span> 
        </div>
        <div class="bx inquire">
            <div class="inquire_bt">
                货源查询 <i class="el-icon-arrow-right"></i>
            </div>
            <div class="condition">
                <div class="condition_col">
                    <div class="condition_tit">货物地址：</div>
                    <div class="condition_province">
                        <div @click="provincesClick('')" :class="!searchForm.deliverProvince ? 'ok' : ''">不限</div>
                        <div v-for="(item,index) in provinces" :key="index"
                            :class="searchForm.deliverProvince == item ? 'ok' : ''" @click="provincesClick(item)">{{ item }}</div>
                    </div>
                </div>
                <div class="condition_col" style="align-items: center;">
                    <div class="condition_tit">货物名称：</div>
                    <el-input v-model="searchForm.goodsName" placeholder="请输入" style="width: 200px;" size="mini"></el-input>
                </div>
                <div class="condition_col" style="align-items: center;">
                    <div class="condition_tit">路线搜索：</div>
                    <el-cascader :options="options" v-model="searchForm.deliverCode" size="mini" style="width: 200px;" filterable clearable></el-cascader>
                    <i class="el-icon-minus" style="margin: 0 10px;color: #999;"></i>
                    <el-cascader :options="options" v-model="searchForm.receivingCode" size="mini" style="width: 200px;" filterable clearable></el-cascader>
                </div>
                <div class="condition_col" style="align-items: center;">
                    <div class="condition_tit">发布时间：</div>
                    <el-date-picker v-model="searchForm.beginCreateTime" type="date" 
                        placeholder="开始时间" size="mini" style="width: 200px;" filterable
                        value-format="yyyy-MM-dd" format="yyyy-MM-dd"></el-date-picker>
                    <i class="el-icon-minus" style="margin: 0 10px;color: #999;"></i>
                    <el-date-picker v-model="searchForm.endCreateTime" type="date" 
                        placeholder="结束时间" size="mini" style="width: 200px;" filterable
                        value-format="yyyy-MM-dd" format="yyyy-MM-dd"></el-date-picker>
                </div>  
                <div style="display: flex;flex-direction: row-reverse;">
                    <div class="relation" @click="queryList(true)">
                        查询
                    </div>
                    <div class="reset" @click="reset(true)">
                        重置
                    </div>
                </div>
            </div>
        </div>
        <div class="bx inquire">
            <ul class="inquire_tit">
                <li>出发城市/到达城市</li>
                <li style="text-align: center;">货物名称</li>
                <li style="text-align: center;">总量/待成交量</li>
                <li style="text-align: center;">抢单时间</li>
                <li style="text-align: center;">期望价格</li>
            </ul>
            <div style="background: #fff;" class="nr">
                <div v-for="item in content" :key="item.id" style="border-bottom: 1px solid rgba(0, 0, 0, 0.1);margin-bottom: 21px;">
                    <ul style="border-bottom: 2px dashed rgba(34,31,31,0.05);">
                        <li style="text-align: center;">
                            <div class="flex_row_center" style="margin-bottom: 18px;"><div class="install">装</div>{{item.deliverCity||'---'}} {{ item.deliverArea||'---' }}</div>
                            <div class="flex_row_center" style="margin-bottom: 18px;"><div class="discharge">卸</div>{{item.receivingCity||'---'}} {{ item.receivingArea||'---' }}</div>
                        </li>
                        <li style="text-align: center;">{{ item.goodsName }}</li>
                        <li style="text-align: center;">{{ item.surplusGoodsTotal || '---' }}吨</li>
                        <li style="text-align: center;">{{ item.beginSnatchOrdersTime || '---' }}</li>
                        <li style="text-align: center;">{{ item.unitPrice + item.unitPriceCy }}</li>
                    </ul>
                    <div style="height: 40px; display: flex;flex-direction: row;align-items: center;">
                        <img src="../assets/img/rz.png" alt="" style="width: 22px;height: 22px;margin-top: 2px;">
                        <div style="margin-right: 10px;font-size: 14px;
                            font-family:;">
                            {{item.certificationStatus == '20' ? '已认证' : '未认证'}} {{ item.carryEnterpriseName || '---' }}</div>
                        <div style="color: rgba(0,0,0,0.26);font-size: 16px;">{{ item.createdTime }} 更新</div>
                    </div>
                </div>
                <div style="width: 100%;display: flex;justify-content: center;margin: 200px 0;" v-if="content.length == 0">
                    <img src="../assets/img/zwsjlist.png" alt="">
                </div>
                <div style="display: flex;flex-direction: row-reverse;margin-top: 10px;">
                    <el-pagination
                        background  
                        layout="prev, pager, next"
                        :total="total"
                        @current-change="current">
                    </el-pagination>
                </div>
            </div>
        </div>
        <!-- 底部 -->
        <nav-footer></nav-footer>

    </div>
</template>

<script>
import NavbarHeader from "../components/home/newHeader.vue"
import NavFooter from "../components/common/NavFooter.vue"
import { regionData, CodeToText, TextToCode } from 'element-china-area-data'
export default {
    components: {
        NavbarHeader,
        NavFooter
    },
    data () {
        return {
            addressArr: [],
            value1: null,
            value: '',
            optionsArr: [{
                value: '选项1',
                label: '黄金糕'
            }, {
                value: '选项2',
                label: '双皮奶'
            }],
            options: regionData,
            provinces: [
                "北京", "天津", "上海", "重庆", "新疆", "西藏", "宁夏", "内蒙古",
                "广西", "黑龙江", "吉林", "辽宁", "河北", "山东", "江苏", "安徽",
                "浙江", "福建", "广东", "海南", "云南", "贵州", "四川", "湖南",
                "湖北", "河南", "山西", "陕西", "甘肃", "青海", "江西", "台湾", "香港", "澳门"
            ],
            total: 0,
            searchForm: {
                pageSize: 10,
                pageNumber: 1
            },
            content: []
        }
    },
    computed: {

    },
    mounted () {
        this.queryList();
    },
    methods: {
        reset () {
            this.searchForm.deliverProvince = ''
            this.searchForm.goodsName = ''
            this.searchForm.deliverCode = null
            this.searchForm.receivingCode = null
            this.searchForm.beginCreateTime = null
            this.searchForm.endCreateTime = null
        },
        //货物地址选择
        provincesClick (item) {
            this.searchForm.deliverProvince = item
            this.searchForm = { ...this.searchForm }
        },
        queryList (isHomePage) {
            if (isHomePage) {
                this.searchForm.pageNumber = 1;
            }
            let serverUrl = this.$serverUrl + '/wz/api/page'
            this.$axios.post(serverUrl, this.searchForm).then(res => {
                if (res.data.code == '200') {
                    this.content = res.data.data.content
                    this.total = res.data.data.totalElements
                }
            })
        },
        //切换页数
        current (val) {
            this.searchForm.pageNumber = val
            this.queryList()
        },
    },
}
</script>
<style>
.el-dialog__wrapper {
    z-index: 100000000 !important;
}
.el-select-dropdown {
    z-index: 100000000000000000000000 !important;
}
.el-picker-panel {
    z-index: 100000000000000000000000 !important;
}
</style>

<style lang="scss" scoped>
.index {
    width: 100%;
    background: #f5f5f5;
    &::-webkit-scrollbar {
        display: none;
    }
    .name {
        font-size: 14px;
        color: #000;
    }
}
.box_title_right {
    width: 1200px;
    margin: 0 auto;
    font-size: 15px;
    color: #999999;
    padding: 20px 0;
    a {
        color: #999999;
    }
}
.inquire {
    margin-bottom: 20px;
    .inquire_bt {
        background: #ececec;
        padding-left: 20px;
        font-size: 14px;
        height: 40px;
        line-height: 40px;
    }
    .condition {
        background: #fff;
        padding: 20px;
        font-size: 14px;
        .condition_col {
            display: flex;
            flex-direction: row;
            margin: 20px;
            font-size: 16px;
            .condition_tit {
                line-height: 30px;
                width: 100px;
            }
            .condition_province {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                div {
                    width: 50px;
                    height: 30px;
                    border: 1px solid transparent;
                    border-radius: 3px;
                    text-align: center;
                    line-height: 30px;
                    margin-right: 10px;
                    cursor: pointer;
                    &:hover {
                        color: #c50007;
                    }
                }
            }
        }
    }
    .inquire_tit {
        display: flex;
        justify-content: space-between;
        background: #ececec;
        font-weight: 900;
        padding: 0 20px;
        font-size: 14px;
        font-weight: 600;
        height: 40px;
        line-height: 40px;
        li {
            flex: 2;
        }
    }
    .nr {
        padding: 20px;
        ul {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            li {
                flex: 2;
            }
        }
        img {
            margin-right: 4px;
        }
        .turnover_time {
            color: rgba(0, 0, 0, 0.26);
            margin-left: 20px;
        }
    }
}
.install {
    width: 24px;
    height: 24px;
    background: #34cb91;
    color: #fff;
    text-align: center;
    line-height: 24px;
    margin-right: 10px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
}
.discharge {
    width: 24px;
    height: 24px;
    background: #ff4238;
    color: #fff;
    text-align: center;
    line-height: 24px;
    margin-right: 10px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
}
.flex_row_center {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 18px;
    font-weight: 800;
}
/deep/ .active {
    background-color: #c50007 !important;
}
.relation {
    width: 60px;
    cursor: pointer;
    padding: 0 10px;
    height: 30px;
    background-color: #c50007 !important;
    line-height: 30px;
    border-radius: 4px;
    text-align: center;
    color: #fff;
}
.reset {
    width: 60px;
    cursor: pointer;
    padding: 0 10px;
    border: 1px solid #c50007;
    height: 30px;
    line-height: 30px;
    border-radius: 4px;
    text-align: center;
    color: #c50007;
    margin-right: 10px;
}
.ok {
    color: #c50007;
    border: 1px solid #c50007 !important;
}
</style>